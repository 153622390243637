import { validate_pers_number } from '@/core/services/personnr';

export const validate_state = (state) => {
  return state?.dirty
    ? !state?.error
    : null;
}

export const validate_phone = (phone) => {
  const regx = /^\+?[0-9]{6,14}$/;
  return regx.test(phone)
}

export const validate_dob = (dob) => {
  const formatted = validate_pers_number(dob);
  return formatted.success;
}

export const validate_year = (year) => {
  // can't extend current year
  const today = new Date();
  return year >= 1900 && year <= today.getFullYear();
}

export const validate_month = (month, year) => {
  const iv = parseInt(month, 10);
  const iy = parseInt(year, 10);

  if (iv >= 1 && iv <= 12) {
    // if current year then month cannot extend current month
    const today = new Date();

    return iy === today.getFullYear()
      ? iv <= today.getMonth()+1
      : true;
  }

  return false;
}

export const validate_day = (day, month, year) => {
  const iv = parseInt(day, 10);
  const im = parseInt(month, 10);
  const iy = parseInt(year, 10);

  if (iv >= 1 && iv <=31) {
    const today = new Date();
    // if current year and current month then date cannot extend current date
    if (iy === today.getFullYear() && im === today.getMonth()+1) {
      return iv <= today.getDate();
    }
    // check if not exceeds days in month
    const date = new Date(year, month, 0).getDate();
    return isNaN(date) ? true :  iv <= date;
  }
  return false;
}
