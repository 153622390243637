<template>
  <div class="container">
    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">
      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-mailbox</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{ $t('MEMBER.ADDRESS_HEADER') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>

      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form && company.profile">

      <b-row>
        <b-col sm="12" md="4" v-if="company.profile.co">
          <b-form-group :label="$t('MEMBER.CO')">
            <b-form-input @change="save_data" v-model="local_member.co"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.st">
          <b-form-group :label="$t('MEMBER.ADDRESS')">
            <b-form-input @change="save_data" v-model="local_member.address"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.zi">
          <b-form-group :label="$t('MEMBER.ZIPCODE')">
            <b-form-input @change="save_data" v-model="local_member.zipcode"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="4" v-if="company.profile.pa">
          <b-form-group :label="$t('MEMBER.POST')">
            <b-form-input @change="save_data" v-model="local_member.post"/>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="4" v-if="company.profile.rc">
          <b-form-group :label="$t('MEMBER.REGION_CODE')">
            <b-form-select @change="save_data" :options="region_code_options" v-model="local_member.region_code"/>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="4" v-if="company.profile.cc">
          <b-form-group :label="$t('MEMBER.COMMUNE')">
            <b-form-select @change="save_data" :options="commune_code_options" v-model="local_member.commune_code"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="4" v-if="company.profile.country">
          <b-form-group :label="$t('MEMBER.COUNTRY')">
            <b-form-select @change="save_data" :options="$t('COUNTRIES')" text-field="value" value-field="key" v-model="local_member.country"/>
          </b-form-group>
        </b-col>

      </b-row>

      
      <RightSaveButton
        ref="save-button"
        :text="$t('COMMON.SAVE')"
        @clicked="save_button_clicked()"
      />

    </div>
    <hr/>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';


export default {

  name: 'MemberAddressDetailsComponent',

  props: ['member','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    RightSaveButton
  },

  watch: {
    'member.region_code'() {
      if (this.member.region_code in this.regions) {
        this.set_communes(this.regions[this.member.region_code].communes);
      }
    },


    member: {
      deep: true,
      handler(val) {
        this.local_member = { ...this.member }
      }
    },

  },

  computed: {

  },

  methods: {
    save_button_clicked() {
      setTimeout(()=>{
        this.$refs['save-button'].stop();
      },500);

      this.save_data();
    },

    async save_data() {

      this.$emit('save_data', this.local_member);

    },

    async load_regions() {

      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');

        this.regions = res.data.regions;
        this.region_code_options = [];

        for (var k in this.regions) {
          this.region_code_options.push({ value: k, text: this.regions[k].region });
        }

        this.region_code_options.sort(function(a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

        if (this.member.region_code in this.regions) {
          this.set_communes(this.regions[this.member.region_code].communes);
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_LIST_REGIONS'));
      }
    },


    set_communes(communes) {
      this.commune_code_options = [];

      for (var k in communes) {
        this.commune_code_options.push({ value: k, text: communes[k] });
      }

      this.commune_code_options.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },

  },

  mounted() {
    this.show_form = this.expanded;

    this.local_member = { ...this.member };

    this.load_regions();
  },

  data() {
    return {

      local_member: {},

      regions: {},

      region_code_options: [],

      commune_code_options: [],

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MALE'), value: 'M' },
        { text: this.$t('COMMON.FEMALE'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

    };
  }
};

</script>


<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
