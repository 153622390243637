<template>
  <div>
    <div class="d-flex justify-content-end mb-4">

      <div class="card-toolbar">

        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="create_example_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('PAGES.EXAMPLES.NEW') }} </a
        >
      </div>
    </div>

    <b-row class="mb-2 justify-content-end">
      <b-col md="12" lg="3">
        <div class="d-sm-flex justify-content-end text-right float-right align-bottom w-100">
          <b-form-select class="pagination-select" v-model="per_page" :options="per_page_options"></b-form-select>
          <b-pagination
            class="mb-0 mr-2"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="example-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>


    <b-table
      id="example-table"
      :fields="headers"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      class="mh-100"
      sticky-header
    >

      <template #cell(actions)="row">
        <div class='justify-content-end d-flex'>

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mx-3"
            @click.prevent="select_example_clicked(row.item.id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>

          <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_example_clicked(row.item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
    </b-table>

  </div>

</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding: 0.60rem 2rem 0.65rem 1rem;
  margin-right: 12px;
  max-width: 100px;
}

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';


export default {
  name: "examples-table",
  props: ["items"],
  emits: ["select_example_clicked"],
  components: {

  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    create_example_clicked() {},

    select_example_clicked(id) {
      this.$emit('select_example_clicked', id);
    }

  },
  data() {
    return {
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'text',
          label: 'Text',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'email',
          label: 'E-mail',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'actions',
          label: ' ',
          thClass: 'w-120px text-right pr-12 align-middle',
        },
      ],

    };
  }
};
</script>
