<template>
  <div class="container">
    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-file-cabinet</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{$t('AUTH.MENU.FILES')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>
      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form">


      <div v-if="allow_upload" class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
        <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload()" />
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="upload_file_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('FILE.UPLOAD')}}</a
        >

      </div>


      <b-table
        id="files-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="filesTable"
      >
        <template #cell(download)="row">
          <a href="" @click.prevent="download_file(row.item)">{{ $t('FILE.DOWNLOAD') }}</a>
        </template>

      </b-table>

      <p v-if="items.length === 0">{{$t('MEMBER.NO_FILES')}}</p>


    </div>

    <hr/>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberTableOrders',

  props: ['allow_upload', 'member','items','company','expanded'],
  emits: ['save_data', 'created'],
  mixins: [ toasts ],

  components: {},

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    upload_file_clicked() {
      this.$refs.file.click();
    },

    async upload_file() {
      try {
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('file_source', 'member');
        formData.append('file_type', 'MEMBER_PROFILE');


        const res = await axios.post('/fileupload/member', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));

          this.$emit('created', res.data.fileobjs[0]);
          return;
        }

      }
      catch (err) {
        console.error('upload error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));

    },

    handle_file_upload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.upload_file();
    },

    async download_file(item) {
      try {
        downloadWithAxios(get_base_url() + `/dlfile/${item.linkstr}`, item.name);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_DOWNLOAD'));
      }

    },

    async save_data() {

      this.$emit('save_data', this.member);

    }

  },

  mounted() {
    this.show_form = this.expanded;

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      file: null,

      fields: [
        {
          key: 'file_id',
          label: this.$t('FILE.FILE_ID'),
          mobile: true,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'created_at',
          label: this.$t('PAYMENT.CREATED_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'name',
          label: this.$t('FILE.FILENAME'),
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'download',
          label: '',
          mobile: false,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
