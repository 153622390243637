<template>
  <div id="page-examples">
    <h5 class="m-4">{{ $t('AUTH.MENU.EDUCATION') }}</h5>

    <b-card class="px-0">

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('PAGES.EXAMPLES.EDITOR') }}</v-tab>
        <v-tab>Fontawesome icons</v-tab>
        <v-tab>{{ $t('COMMON.SETTINGS') }}</v-tab>
        <v-tab>{{ $t('PUBLIC.DONATION.DONATION') }}</v-tab>
        <v-tab>{{ $t('MEMBER.MEMBER') }}</v-tab>

        <v-tab-item>

          <ExampleTable 
            :items="examples"
            @select_example_clicked="select_example_clicked"
            @create_example_clicked="create_example_clicked"
          />

          <ExampleEditComponent
            class="mt-4"
            ref="example_edit_component"
            v-if="example"
            :example="example" update:example="updated_example"
            @created="created_example"
          />
        </v-tab-item>
        
        <v-tab-item>
          <ListIcons />
          
        </v-tab-item>

        <v-tab-item>
          
        </v-tab-item>

        <v-tab-item>
          <ExampleDonateComponent 
            class="mt-16"
            :company="test_company"
          />
        </v-tab-item>

        <v-tab-item>
          <MemberProfile 
            :member="example_member"
          />
        </v-tab-item>

      </v-tabs>
    </b-card>
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

// Edit component
import ListIcons from './example/ListIcons.vue';
import ExampleEditComponent from './example/ExampleEditComponent.vue';
import ExampleTable from './example/ExampleTable.vue';
import ExampleDonateComponent from './example/ExampleDonateComponent.vue';

import MemberProfile from '../members/loggedin/new_member_profile/MemberProfile.vue';


export default {
  name: 'examples',
  mixins: [ toasts ],
  components: {
    ListIcons,
    ExampleEditComponent,
    ExampleTable,
    ExampleDonateComponent,

    MemberProfile
},
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    //this.example = this.examples[0];
  },
  data() {
    return {

      example_member: {
        "member_id": 272,
        "import_member_id": null,
        "created_at": "2023-08-03 18:22:47",
        "updated_at": "2023-08-03 18:22:47",
        "has_error": 0,
        "dob": "1985-07-09",
        "avd": null,
        "sys": true,
        "def": true,
        "imp": true,
        "rdt": "2023-08-03 18:22:47",
        "fid": 196,
        "hd": 1,
        "rs": 0,
        "public_id": "2000257",
        "activity": null,
        "in_personnr": "19850709-T123",
        "fmt_personnr": "19850709-T123",
        "address": null,
        "invoice_address": null,
        "zipcode": null,
        "invoice_zipcode": null,
        "email": "micke+member@thinkinghat.se",
        "phone": null,
        "landline": null,
        "firstname": "postman1",
        "lastname": "postman2",
        "invoice_firstname": null,
        "invoice_lastname": null,
        "invoice_email": null,
        "invoice_phone": null,
        "source": null,
        "site_id": null,
        "token": null,
        "notes": null,
        "type": null,
        "birthyear": null,
        "birthmonth": null,
        "birthday": null,
        "ssn_last_4": null,
        "is_ssn_valid": "Y",
        "sex": "M",
        "sms_verification_code": null,
        "phone_verified": null,
        "sms_verification_time": null,
        "password": null,
        "reset_token": null,
        "salt": "M6TsTj9F3A5yTNWtgww9ZyRoP30esLZb",
        "lang": null,
        "age": 38,
        "public_sex": "U",
        "country": "SE",
        "ladok": null,
        "login_token": null,
        "commune_code": null,
        "region_code": null,
        "paying": null,
        "lrun": 0,
        "spar": null,
        "post": null,
        "invoice_post": null,
        "invoice_country": null,
        "cl_conf": null,
        "cl_accp": null,
        "cl_rej": null,
        "code": null,
        "co": null,
        "cname": null,
        "lz": 0,
        "membership_item_id": 0,
        "deceased": false,
        "deactivated": false,
        "deleted": false,
        "reg": false,
        "fnx_status": "NONE",
        "fnx_result": "NONE",
        "fnx_last": null,
        "fnx_cn": null,
        "org_number": null,
        "do_autorenew": false,
        "qr_hash": "lZeb9XPng7iOprJvNYX8Or7txtmlMdV7",
        "email_error": false,
        "checked": true,
        "stripe_id": null,
        "deactivate_at": null,
        "skv_status": "PEN_REGISTER",
        "status": "PENDING",
        "skv_period_id": null,
        "accepted_at": null,
        "format_v": "V1",
        "properties": []
    },

      test_company: {
        name: 'En förening'
      },
      examples: [
        {
          id: 1,
          text: 'Test 1',
          date: '2023-01-01',
          email: 'test1@noreply.com',
          use_email: true,
          selected: 0,
          option: 0,
        },
        {
          id: 2,
          text: 'Test 2',
          date: '2023-01-01',
          email: 'test2@noreply.com',
          use_email: true,
          selected: 1,
          option: 1,
        },
        
      ],
      example: {
        id: 1,
        text: '',
        email: '',
        use_email: false,
        date: '',
        selected: null,
        option: null,
        use_a: false,
        use_b: false,
      }
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    // add any methods we need

    select_example_clicked(id) {
      const example = this.examples.find(item => item.id === id);

      if (example) {
        this.example = example;
      }
    },

    create_example_clicked() {
      this.example = {};
      this.$refs['example_edit_component'].reset_form();
    },

    updated_example(example) {
      this.example = example;
    },

    created_example(example) {
      this.example = example;
    }

  }
};
</script>
