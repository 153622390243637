<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER.FIRSTNAME')">

                <b-form-input
                  size="sm"
                  v-model="local_item.rel_member.firstname"
                />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER.LASTNAME')">

                <b-form-input
                  size="sm"
                  v-model="local_item.rel_member.lastname"
                />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER.EMAIL')">

                <b-form-input
                  size="sm"
                  v-model="local_item.rel_member.email"
                />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER.PHONE')">

                <b-form-input
                  size="sm"
                  v-model="local_item.rel_member.phone"
                />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER.PERSONNR')">

                <b-form-input
                  size="sm"
                  v-model="local_item.rel_member.in_personnr"
                />

            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <b-button ref="save" class="save" variant="primary" type="submit">{{$t('COMMON.SAVE')}}</b-button>
            <b-button class="ml-8 cancel" variant="danger" @click="$emit('cancel')" >{{$t('COMMON.CANCEL')}}</b-button>
          </b-col>
        </b-row>
      </b-form>

    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { validate_email } from '@/core/services/email';


export default {

  name: 'MemberFamilyEditor',

  props: ['member','item','creating'],
  emits: ['created', 'updated', 'save_data', 'cancel'],
  mixins: [ toasts ],

  components: {},

  watch: {
    item: {
      deep: true,
      handler(val) {
        this.local_item = { ...val };
      }
    },
    'item.rel_member_id'() {
      if (this.$refs.observer) {
        // need to reset validation when changing template so all fields are set to neutral state
        this.reset_validation();
      }
    },

    member: {
      deep: true,
      handler(val) {
        this.local_member = { ...this.member }
      }
    },
  },

  computed: {
    ...mapGetters([ ]),

  },


  mounted() {
    this.local_item = { ...this.item };
    this.local_member = { ...this.member };
  },

  methods: {


    reset_validation() {
      if (this.item) {
        this.$nextTick(() => this.$refs.observer.reset());
      }
    },


    async validate() {
      return await this.$refs.observer.validate();
    },

    validate_state(field, state) {

      if (field === 'in_personnr') {
        const formatted = formatPersonnr(this.local_item.rel_member.in_personnr);

        return (state.dirty || state.validated) ? formatted.valid : null;
      }
      else if (field === 'email') {
        return (state.dirty || state.validated) ? validate_email(this.local_item.rel_member.email) : null;
      }

      const result = (state.dirty || state.validated) ? !state.errors[0] : null;
      return result;
    },

    async on_submit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {

        if (this.creating) {
          await this.create_family_member();
        }
        else {
          await this.update_family_member();
        }
      }
    },

    async create_family_member() {
      try {

        const res = await axios.post(`/member_relation/member`, this.local_item.rel_member);

        if (res.status === 201) {
          this.$emit('created', res.data);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBER_RELATION.CREATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_RELATION.UNABLE_CREATE'));
      }
    },

    async update_family_member() {
      try {

        const res = await axios.put(`/member_relation/member/${this.local_item.id}`, this.local_item);

        if (res.status === 204) {
          this.$emit('updated', res.data);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBER_RELATION.UPDATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_RELATION.UNABLE_UPDATE'));
      }
    },


    async save_data() {

      this.$emit('save_data', this.local_member);

    },

  },


  data() {
    return {
      local_item: {},
      local_member: {},

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MAN'), value: 'M' },
        { text: this.$t('COMMON.WOMAN'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
