<template>
  <div class="container">

    <b-modal ref="qr-modal" size="xl">
      <b-row align-h="center">
        <vue-qrcode
          v-if="qr_value"
          :value="qr_value"
          :width="calc_big_qr_width"
          style="width: 100%"
        />
      </b-row>
    </b-modal>
    <b-row class="top-row">
      <b-col>

        <b-row align-h="center">
          <vue-qrcode
            v-if="qr_value"
            :value="qr_value"
            :width="128"
            style="width: 128px"
          />
        </b-row>
        <b-row align-h="center">
          <b-button
            id="UserQR_show"
            variant="primary"
            @click.prevent="show_qr_modal()"
            style="width:158px"
          >
            {{ showUser_QR ? $t('MEMBER.HIDE_CARD') : $t('MEMBER.SHOW_CARD') }}
          </b-button>
        </b-row>

      </b-col>
    </b-row>

  </div>

</template>

<style lang="css" scoped>

.top-row {
  margin-bottom: 16px;
}


.container {
  content: "";
  display: block;

}


:deep .td-short {
  width: 32px;
  overflow: hidden;
}

:deep .td-short2 {
  width: 56px;
  overflow: hidden;
}

:deep .td-short3 {
  width: 76px;
  overflow: hidden;
}

:deep .td-short4 {
  width: 96px;
  overflow: hidden;
}

:deep .td-short5 {
  width: 116px;
  overflow: hidden;
}

:deep td {
  vertical-align: middle !important;
}

</style>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { is_mobile } from '@/core/services/utils';
import VueQrcode from 'vue-qrcode';

export default {

  name: 'MemberQRComponent',

  props: ['member','company','instance_id','qr_hash'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    VueQrcode
  },

  watch: {
    member() {

    }
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    calc_big_qr_width() {
      return is_mobile() ? window.innerWidth * 0.8 : 1024;
    },

    qr_value() {
      return 'https://' + window.location.hostname + '/ml-scan-qr-member/' + this.qr_hash;
    }
  },

  methods: {

    show_qr_modal() {
      this.$refs['qr-modal'].show();
    },


    goto_renew_wizard() {
      location.href = '/x/' + this.member_renew.linkstr;
    },

    async save_data() {

      this.$emit('save_data', this.member);

    },

  },

  beforeDestroy() {
    clearInterval(this.qr_refresh_interval);
  },

  mounted() {
    if (this.is_mobile) {

    }
  },

  data() {
    return {

      qr_refresh_interval: null,

      showUser_QR: false,

      qr_width: 128,

      show_form: true,

    };
  }
};

</script>
