<template>
  <div class="container">
    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">

        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-email-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{ $t('MEMBER.CONTACT') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>


      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form && company.profile">

      <b-row>
        <b-col sm="12" md="4" v-if="company.profile.em">
          <b-form-group :label="$t('MEMBER.EMAIL')">
            <b-form-input @change="change_save_data" v-model="local_member.email"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.ph">
          <b-form-group :label="$t('MEMBER.PHONE')">
            <b-form-input @change="change_save_data" v-model="local_member.phone"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.ph">
          <b-form-group :label="$t('MEMBER.LANDLINE')">
            <b-form-input @change="change_save_data" v-model="local_member.landline"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="checkbox-list">

            <div class="d-flex align-items-center mb-6">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  v-model="local_member.sys"
                  :value="true"
                  @change="change_save_data"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer mb-4">{{ $t('MEMBER.SYS') }}</span>
            </div>

            <div class="d-flex align-items-center mb-6">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  v-model="local_member.def"
                  :value="true"
                  @change="change_save_data"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer mb-4">{{ $t('MEMBER.DEF') }}</span>
            </div>

            <div class="d-flex align-items-center mb-6">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  v-model="local_member.imp"
                  :value="true"
                  @change="change_save_data"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer mb-4">{{ $t('MEMBER.IMP') }}</span>
            </div>

          </div>
        </b-col>
      </b-row>

      <RightSaveButton
        ref="save-button"
        :text="$t('COMMON.SAVE')"
        @clicked="save_button_clicked()"
      />

    </div>
    <hr/>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {

  name: 'MemberContactDetailsComponent',

  props: ['member','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    RightSaveButton
  },

  watch: {

    member: {
      deep: true,
      handler(val) {
        this.local_member = { ...this.member }
      }
    },
  },

  computed: {

  },

  methods: {
    change_save_data() {},
    save_button_clicked() {
      setTimeout(()=>{
        this.$refs['save-button'].stop();
      },500);

      this.save_data();
    },

    async save_data() {
      this.$emit('save_data', this.local_member);
    }

  },

  mounted() {
    this.local_member = { ...this.local_member };
    this.show_form = this.expanded;
  },

  data() {
    return {

      local_member: {},

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MALE'), value: 'M' },
        { text: this.$t('COMMON.FEMALE'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
