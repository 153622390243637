
<template>
  <div class="container">

    <b-modal
      ref="edit-form"
      hide-footer
      size="lg"
      >

      <b-row>
        <b-col class="ml-10 mb-4">
          <p>{{ $t('MEMBER.SELECT_COMPANIES', { period: this.period_name }) }}</p>
        </b-col>
      </b-row>


      <div class="card-body pt-0 pb-3">

        <b-table
          id="companies-table"
          style="width: 100%; table-layout: fixed;"
          class="table-striped"
          :fields="fields"
          :items="company_options"
          head-variant="light"
          ref="companies-table"
        >

        <template #cell(selected)="row">
          <v-checkbox
            :ripple="false"
            v-model="row.item.selected"
            class="my-0 py-0"
            color="#3699FF"
            hide-details
            @change="selection_changed(row.item.selected, row.item.company_id)"
          ></v-checkbox>
        </template>

        </b-table>

      </div>

      <b-row>
        <b-col>
          <v-checkbox
            :label="$t('MEMBER.DONT_SHOW_AGAIN')"
            :ripple="false"
            v-model="dont_show_again"

            @change="dont_show_again_changed()"
          ></v-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.SAVE')"
            :spin="true"
            @clicked="on_submit"
            @close="$refs['edit-form'].hide()"
            ref="saveButton"
          />
        </b-col>
      </b-row>

    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';

import Confirm from '@/view/components/Confirm.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'MemberAddMembershipsComponent',

  props: ['member','memberships','companies'],
  emits: ['created'],
  mixins: [ toasts ],

  components: {
    Confirm,
    RightModalSaveAndCloseButtons
  },

  watch: {
    items() {
      console.log('items changed')
    }
  },

  computed: {
    company_options() {
      if (!this.companies) {
        return [];
      }

      // dont include membership we already have
      const active_memberships = this.memberships.filter(item => item.active === 1);

      const active_company_ids = active_memberships.map(item => item.company_id);

      const companies = this.companies.filter(item => !active_company_ids.includes(item.company_id));

      return companies.map(item => {
        return {
          company_id: item.company_id,
          name: item.name
        };
      });
    },
    period_name() {
      return new Date().getFullYear();
    },

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId','currentPeriodId']),

  },

  methods: {

    async dont_show_again_changed() {

      const value = this.dont_show_again ? 'true' : 'false';

      const res = await axios.put(`/member/setting/${this.SETTING_KEY_DONT_SHOW_AGAIN}`, { value });

      if (res.status === 204) {
        console.log('setting updated');
      }
      else {
        console.error('setting update failed');
      }
    },

    async on_submit() {
      try {
        const res = await axios.post(`/member_company/add`, {
          company_ids: this.company_ids
        });

        if (res.status === 201) {
          this.$refs['edit-form'].hide();
          this.$emit('created', res.data);
        }
      }
      catch (err) {
        console.error('on_submit error', err);
      }
    },

    selection_changed(selected, company_id) {
      if (selected) {
        this.company_ids.push(company_id);
      }
      else {
        this.company_ids = this.company_ids.filter(id => id !== company_id);
      }

    }

  },

  mounted() {

    // check if mute setting is set

    const mute = this.member.settings.find(item => item.name === this.SETTING_KEY_DONT_SHOW_AGAIN && item.value === 'true');

    if (mute) {
      console.log('muted');
      return;
    }

    // only show if we have active memberships
    const active_membership = this.memberships.filter(item => item.active === 1);

    if (active_membership && active_membership.length > 0) {
      this.$refs['edit-form'].show();
    }

  },

  data() {
    return {

      SETTING_KEY_DONT_SHOW_AGAIN: 'ADD_MEMBERSHIP_MUTE',

      dont_show_again: false,

      company_ids: [],

      fields: [

        {
          visible: true,
          key: 'selected',
          label: '',
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          visible: true,
          key: 'name',
          label: this.$t('COMPANY.NAME'),
        },

      ]

    };
  }
};

</script>


<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
