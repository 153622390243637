<template>
  <div>
    <b-card
      title=""
      class="mb-2 edit-example-container"
      ref="edit-example"
      id="edit-example"
      hide-footer
    >

    <required-fields-info/>

    <b-form ref="edit-form" class="mt-8" @submit.prevent="on_submit">
      <b-form-group class="col-12" id="group-id" label="ID" label-for="input-id">
        <b-form-input
          id="input-id"
          v-model="local_example.id"
          type="text"
          disabled
        />
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12" id="group-text" :label="$t('PAGES.EXAMPLES.NAME')" label-for="field-text">
        <b-form-input
          id="field-text"
          v-model="local_example.text"
          type="text"
          :placeholder="$t('PAGES.EXAMPLES.NAME')"
          :state="text_state"
        />
        <b-form-invalid-feedback>Invalid text, must be at least 2 characters.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12" id="group-email">

        <div class="d-flex align-items-center mb-4">
          <label class="checkbox checkbox-outline checkbox-success">
            <input type="checkbox" name="enable_ab" v-model="local_example.use_email" />
            <span></span>
          </label>
          <span class="ml-3">Use e-mail</span>
        </div>

          <b-form-input v-if="local_example.use_email"
            id="field-email"
            v-model="local_example.email"
            type="email"
            placeholder="e-mail"
            :state="email_state"
          />
          <b-form-invalid-feedback>Invalid email, must be in format a@b.co .</b-form-invalid-feedback>

      </b-form-group>

      <b-form-group  class="col-lg-6 col-sm-12" id="select-group-option" label="Options" label-for="select-option">
        <b-form-select
          id="field-option"
          v-model="local_example.selected"
          :options="select_options"
          :state="selected_state"
        />
        <b-form-invalid-feedback>Invalid option, please select a option.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12" id="input-date-group" :label="$t('PAGES.EXAMPLES.DATE')" label-for="input-date">
        <memlist-date-picker
          id="field-date"
          v-model="local_example.date"
          :state="date_state"
        />
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12">
      <div>
        <label class="radio radio-outline radio-success mb-4">
          <input type="radio" v-model="local_example.option" value="0" name="radio-option"/>
          <span style="margin-right: 12px;"></span>
          Option 1
        </label>
        <label class="radio radio-outline radio-success mb-4">
          <input type="radio" v-model="local_example.option" value="1" name="radio-option" />
          <span style="margin-right: 12px;"></span>
          Option 2
        </label>
        <label class="radio radio-outline radio-success mb-4">
          <input type="radio" v-model="local_example.option" value="2" name="radio-option" />
          <span style="margin-right: 12px;"></span>
          Option 3
        </label>
      </div>
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12">

        <div class="d-flex align-items-center mb-4">
          <label class="checkbox checkbox-outline checkbox-success">
            <input type="checkbox" name="use_a" v-model="local_example.use_a" />
            <span></span>
          </label>
          <span class="ml-3">Use A</span>
        </div>

        <div class="d-flex align-items-center mb-4">
          <label class="checkbox checkbox-outline checkbox-success">
            <input type="checkbox" name="use_b" v-model="local_example.use_b" />
            <span></span>
          </label>
          <span class="ml-3">Use B</span>
        </div>

      </b-form-group>

      <div class="mt-4">
        <b-button type="submit" :disabled="!is_valid" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
      </div>

    </b-form>

    </b-card>
  </div>
</template>

<style lang="scss" scoped>

/* add some styles here */

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import { useVuelidate } from '@vuelidate/core';
import { email, minLength, required } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';

export default {

  name: 'example-edit',

  mixins: [ toasts ],

  components: {
    // add other components here
    TipLabel, RequiredFieldsInfo,
  },

  props: {
    example: {
      type: Object,
      required: true
    }
  },

  emits: ['update:example'],
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      local_example: {
        text: { required, minLength: minLength(2) },
        email: { required, email },
        selected: { required },
        date: { required },
      }
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS']),
    text_state() {
      return validate_state({ dirty: this.v$.local_example.text.$dirty, error: this.v$.local_example.text.$error })
    },
    email_state() {
      return validate_state({ dirty: this.v$.local_example.email.$dirty, error: this.v$.local_example.email.$error })
    },
    selected_state() {
      return validate_state({ dirty: this.v$.local_example.selected.$dirty, error: this.v$.local_example.selected.$error })
    },
    date_state() {
      return validate_state({ dirty: this.v$.local_example.date.$dirty, error: this.v$.local_example.date.$error })
    },
    // check that the form is valid
    is_valid() {
      return true
    },
    // calculated, changed when example.id is updated
    creating() {
      return isNaN(parseInt(this.local_example.id+''));
    }
  },
  // when component is mounted/rendered for the first time
  mounted() {
    if (this.example) {
      this.local_example = { ...this.example }
    }
  },
  // local data to the component can be stored here
  data() {
    return {
      local_example: {},
      status: null,
      select_options: [
        { value: 0, text: 'Option 1' },
        { value: 1, text: 'Option 2' },
        { value: 2, text: 'Disabled', disabled: true }
      ]
    };
  },

  watch: {
    // when examples has changed in the form
    local_example: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        // emit the example updated, will emit the form in its entirety
        // example: val = { id: 1, name: 'test', name2: 'test2' }
        this.$emit('update:example', val);
      }
    },
  },
  methods: {
    // add any methods we need
    reset_form() {
      this.local_example = {}
    },

    async on_submit() { // submit was clicked
      const valid = await this.v$.$validate();

      if (!valid) {
        this.v$.$touch();
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        return
      }

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EXAMPLES.ON_SUBMIT'));
      this.$emit('update:example', this.local_example);
    }
  }
};
</script>
