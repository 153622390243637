<template>
  <div class="row">
    <div class="column">
      <p>{{ group.name }}</p>
    </div>
    <div class="column">

      <select v-model="local_value" id="property_select">
        <option :value="null">{{ $t('PAGES.ATTRIBUTES.NONE') }}</option>
        <option
          v-for="prop of property_options"
          :key="prop.value"
          :value="prop.value"
        >{{ prop.text }}</option>
        
      </select>

    </div>
  </div>
  
</template>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberSectionAttributesRow',

  props: ['group'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {},

  watch: {
    group: {
      handler(newVal) {
        if (this.group) {
          this.locked = true;
          this.local_value = this.group.selected_prop_id;
          this.locked = false;
        }
      },
      deep: true,
      immediate: true,
    },

    local_value(newValue) {
      if (this.locked) {
        return;
      }

      this.put_profile_attributes();
    },
  
  },

  computed: {

    property_options() {

      console.log('group', this.group);

      if (!this.group && !this.group.properties) {
        return [];
      }

      return this.group.properties.map(item => ({ text: item.name, value: item.prop_id }))
    },
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {
    update_selected_prop(field, value) {
      console.log('update', field, value);
    },

    async save_data() {

      this.$emit('save_data', this.member);

    },

    async put_profile_attributes() {
      try {
        const res = await axios.put(`/property_group/${this.group.group_id}/profile`, { prop_id: this.local_value });

        if (res.status === 200) {
          console.log('updated');
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.ATTRIBUTES.UPDATED'));
        }
      }
      catch (err) {
        console.error('put_profile_attributes', err);
      }
    }

  },

  async mounted() {
    this.show_form = this.expanded;

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }

  },

  data() {
    return {

      locked: false,

      local_value: null,

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";

  
  /* Reset default margin and padding */
select {
  border: 1px solid #444444;
  -webkit-appearance: auto;
  padding: 8px;
  min-width: 200px;
  margin-top: 11px;
  border-radius: 12px;
}

/* Container styles */
.container {
  width: 100%;
  margin: 0 auto;
}

/* Row styles */
.row {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap if necessary */
}

/* Column styles */
.column {
  flex: 0 0 50%; /* Each column takes up 50% of the row's width */
  padding: 10px; /* Optional spacing */
}

.column p {
  background-color: #ffffff; /* Optional styling */
  padding: 20px;
  text-align: center;
  border: 1px solid #badbcc;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .column {
    flex: 0 0 100%; /* Columns take full width on small screens */
  }
}



</style>
