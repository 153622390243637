<template>
  <div>
    <b-card
      title=""
      class="mb-2 donate-container"
      ref="edit-donate"
      id="edit-donate"
      hide-footer
    >

      <b-form ref="edit-form" class="mt-8" @submit.prevent="on_submit">

        <b-row>
          <b-form-group class="col-12" id="group-name" :label="$t('PUBLIC.DONATION.FROM_NAME')" label-for="input-from_name">
            <b-form-input
              id="input-from_name"
              v-model="from_name"
              type="text"
            />
          </b-form-group>
        </b-row>

        <b-row>
          <b-col>
            <p class="donation-input-header">{{ $t('PUBLIC.DONATION.ENTER_AMOUNT') }}</p>
            <div class="donation-input">
              <div class="input-currency">{{ currency }}</div>
              <div class="donation-input-left">
                <b-form-input
                  class="transparent-input"
                  id="input-amount"
                  v-model="amount"
                  type="text"
                  :placeholder="formatCurrency(0)"
                />
              </div>

              <div class="donation-input-right">
                <div v-if="options.show_suggestions">
                  <b-button @click="amount = options.suggestions[0]" type="button" variant="success">{{ options.suggestions[0] }}</b-button>
                  <b-button @click="amount = options.suggestions[1]" type="button" variant="success">{{ options.suggestions[1] }}</b-button>
                  <b-button @click="amount = options.suggestions[2]" type="button" variant="success">{{ options.suggestions[2] }}</b-button>
                  <b-button @click="amount = options.suggestions[3]" type="button" variant="success">{{ options.suggestions[3] }}</b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-form-group class="col-12" id="group-message" :label="$t('PUBLIC.DONATION.MESSAGE')" label-for="input-message">
            <b-textarea>

            </b-textarea>
          </b-form-group>
        </b-row>

        <b-row>
          <b-col>
            <div><h4>{{ $t('PUBLIC.DONATION.DONATION_TO', { name: company.name }) }}</h4></div>
          </b-col>
          <b-col>
            <div class="float-right"><h4>{{ amount !== null ? formatCurrency(amount) : formatCurrency(0) + ' ' + currency }}</h4></div>
          </b-col>
          <div class="info-content">

          </div>

        </b-row>

        <b-row>
          <b-col>
            <div class="mt-4">
              <b-button size="lg" type="submit" variant="primary">{{$t('PUBLIC.DONATION.START_PAYMENT')}}</b-button>
            </div>
          </b-col>
        </b-row>

      </b-form>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>

.donation-input-header {
  margin-bottom: 6px;
}

.transparent-input {
  background-color: transparent;
  border-color: transparent;

  &.is-invalid {
    border-color: #fd397a;
  }
}

.input-currency {
  float: left;
  padding: 8px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  height: calc(1.5em + 1.3rem + 1px);
  border-right: 1px solid #d3d3d3;
  background-color:#e8e8e8;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.donation-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.donation-input-left {
  float: left;
  min-width: 216px;
}

.donation-input-right {
  float: left;
}

.donation-input-right button {
  margin-right: 3px;
  padding: 4px;
  min-width: 70px;
  margin-top: 3px;
  margin-bottom: 1px;
}

.donate-container {
  max-width: 620px;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';

export default {

  name: 'ExampleDonateComponent',

  mixins: [ toasts ],

  components: {
    TipLabel, RequiredFieldsInfo,
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },
  emits: ['update:example'],
  computed: {},

  // when component is mounted/rendered for the first time
  mounted() {},
  // local data to the component can be stored here
  data() {
    return {
      options: {
        show_suggestions: true,
        suggestions: [50,100,250,500]
      },

      currency: 'SEK',
      from_name: '',
      amount: null,
    };
  },

  watch: {},

  methods: {
    // add any methods we need
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat(this.currency === 'SEK' ? 'sv' : 'en', {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: 2
      });

      return formatter.format(value);
    },

    async on_submit() {
      const valid = await this.v$?.$validate();

      if (this.v$ && !valid) {
        this.v$.$touch();
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        return
      }

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EXAMPLES.ON_SUBMIT'));
      this.$emit('update:example', this.example);
    }
  }
};
</script>
