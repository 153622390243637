<template>
  <div class="container">

    <MemberPasswordUpdate
      ref="passwordModal"
      :isProfilePage="true"
      :member_id="null"
    />

    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{ $t('MEMBER.DETAILS') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>

      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form && company.profile">

      <b-row>
        <b-col sm="12" md="4" v-if="company.profile.na">
          <b-form-group :label="$t('MEMBER.FIRSTNAME')">
            <b-form-input @change="change_save_data" v-model="local_member.firstname"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.na">
          <b-form-group :label="$t('MEMBER.LASTNAME')">
            <b-form-input @change="change_save_data" v-model="local_member.lastname"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.pn">
          <b-form-group :label="$t('MEMBER.PERSONNR')">
            <b-form-input @change="change_save_data" v-model="local_member.in_personnr"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.dob">
          <b-form-group :label="$t('MEMBER.DOB')">
            <b-form-input @change="change_save_data" v-model="local_member.dob"/>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="4">
          <b-form-group :label="$t('MEMBER.PUBLIC_ID')">
            <b-form-input @change="change_save_data" v-model="local_member.public_id" :disabled="true"/>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="4" v-if="company.profile.avd">
          <b-form-group :label="$t('MEMBER.AVD')">
            <b-form-input @change="change_save_data" v-model="local_member.avd"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.cname">
          <b-form-group :label="$t('MEMBER.CNAME')">
            <b-form-input @change="change_save_data" v-model="local_member.cname"/>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" v-if="company.profile.org_number">
          <b-form-group :label="$t('MEMBER.ORG_NUMBER')">
            <b-form-input @change="change_save_data" v-model="local_member.org_number"/>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="4" v-if="company.profile.student_id">
          <b-form-group :label="$t('MEMBER.STUDENT_ID')">
            <b-form-input
              :disabled="true"
              @change="change_save_data" v-model="local_member.student_id"/>
          </b-form-group>
        </b-col>


        <b-col sm="12" md="4" v-if="company.profile.public_sex">
          <b-form-group :label="$t('MEMBER.PUBLIC_SEX')">
            <b-form-select @change="change_save_data" :options="gender_options" v-model="local_member.public_sex"/>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="4">
          <b-form-group :label="$t('MEMBER.PASSWORD')">
            <b-button variant="outline-success" @click="open_password_modal">{{$t('ACCOUNT.UPDATE_PASSWORD')}}</b-button>
          </b-form-group>
        </b-col>

      </b-row>

      <RightSaveButton
        ref="save-button"
        :text="$t('COMMON.SAVE')"
        @clicked="save_button_clicked()"
      />

    </div>
    <hr/>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberPasswordUpdate from '@/view/components/member_editor_v2/MemberPasswordUpdate.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {

  name: 'MemberProfileDetailsComponent',

  props: ['member','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    MemberPasswordUpdate,
    RightSaveButton
  },

  watch: {

    member: {
      deep: true,
      handler(val) {
        this.local_member = { ...this.member }
      }
    },

  },

  computed: {

  },

  methods: {
    change_save_data() {
      // when data field has changed and focus lost
    },
    save_button_clicked() {
      setTimeout(()=>{
        this.$refs['save-button'].stop();
      },500);

      this.save_data();
    },

    open_password_modal() {
      this.$refs['passwordModal'].show();
    },

    async save_data() {
      this.$emit('save_data', this.local_member);
    }

  },

  mounted() {

    this.local_member = { ...this.member };

    this.show_form = this.expanded;
  },

  data() {
    return {

      local_member: {},

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MAN'), value: 'M' },
        { text: this.$t('COMMON.WOMAN'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
